import CONFIG from "../constanta/config";

function getBackground(url) {
  return url;

  if(url !== undefined && url !== null && url !== ""){
    let arr = url.split("/");
    return CONFIG.URL_MEDIA + "/" + arr[arr.length - 1];
  }
  else{
    return "";
  }
}

export default {
  getBackground,
};
