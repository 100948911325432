import { useEffect, useState } from "react";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import FormVerification from "./verification.from";
import FormThankyou from "./thankyou.from";
import FormNameResult from "./name-result";
import FormRFIDResult from "./rfid-result";
import FormThankyouRFID from "./thankyou-rfid.from";
import helper from "../../utils/helper";
import FormQRResult from "./qr-result";
import { useQuery } from "../../hook/useQuery";
import FormRFIDAddition from "./rfid-addition";
import FormReenteryResult from "./rfid-result-reentery";
import FormThankyouRFIDReentry from "./thankyou-reentery.from";

const PageCheckinMobile = (props) => {
  const [options, setOptions] = useState([]);
  const [user, setUser] = useState(null);
  const [state, setState] = useState("verification");
  const [qrCode, setQrCode] = useState(false);
  const query = useQuery();
  const [loadingFetchUser, setLoadingFetchUser] = useState(false);

  // useEffect(() => {
  //   if (Cookies.get("token") === undefined) {
  //     window.location.href = "./home";
  //   }
  // }, []);

  useEffect(() => {
    getLitsUser();
  }, []);

  const getLitsUser = async () => {
    setLoadingFetchUser(true);

    const response = await axios.get(
      CONFIG.URL + "/user/list"
    );

    setLoadingFetchUser(false);

    if (response.status === 200) {
      setOptions(
        response.data.map((item) => {
          return {
            label: item.fullname,
            value: item.id,
          };
        })
      );
    }
  };

  const handleSuccesverification = (data, type, qr=false) => {
    setUser(data);
    if (type === "rfid-reentery") {
      setState("reentery-result");
    } else if (type === "rfid") {
      setState("rfid-result");
    } 
  };

  const handleResetForm = () => {
    setState("verification");
    // window.location.reload();
  };

  const handleSuccessCheckinName = () => {
    setState("thankyou");
  };

  const handleSuccessCheckinRFID = (type) => {
    if(type === "reentery"){
      setState("thankyou-reentery");
    }
    else{
      setState("thankyou-rfid");
    }
   
  };

  const handleSuccessAdditionRFID = () => {
    setState("thankyou-rfid");
  };


  return (
    <>
      {
        <div
        className="w-full min-h-screen flex flex-col justify-start items-center bg-gray-200 px-5 bg-cover bg-no-repeat"
        style={{
          backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg.jpg)`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          height: "100%",
        }}
        >
          <div className="flex flex-col justify-center items-center py-5 w-full">
            <div className="flex flex-col justify-center items-center space-y-2.5 w-full">
              {/* VERIFICATION */}
              {state === "verification" && (
                <>
                  <FormVerification
                  loadingFetchUser={loadingFetchUser}
                    qrCode={qrCode}
                    onSuccess={(data, type, qrCode=false) =>
                      handleSuccesverification(data, type, qrCode)
                    }
                    options={options}
                  />
                </>
              )}

              {/* CHECKIN RESULT */}
              {state === "rfid-result" && (
                <FormRFIDResult
                setting={props?.setting}
                  data={user}
                  ok={() => handleResetForm()}
                  onSuccess={(val) => handleSuccessCheckinRFID(val)}
                />
              )}

              {/* REENTERY RESULT */}
              {state === "reentery-result" && (
                <FormReenteryResult
                setting={props?.setting}
                  user={user}
                  ok={() => handleResetForm()}
                  onSuccess={(val) => handleSuccessCheckinRFID(val)}
                />
              )}

             
              {/* THANK YOU NAME RESULT */}
              {state === "thankyou-reentery" && (
                <FormThankyouRFIDReentry user={user} ok={() => handleResetForm()} />
              )}

              {/* THANK YOU RFID RESULT */}
              {state === "thankyou-rfid" && (
                <FormThankyouRFID ok={() => handleResetForm()} />
              )}
            </div>
          </div>
        </div>
      }
    </>
  );
};

export default PageCheckinMobile;
