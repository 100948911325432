import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";

const FormRFID = (props) => {
  const { rfidAdd, setScreen } = props;
  const [totalField, setTotalField] = useState(0);
  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const onSubmit = (data) => {
    setError("");
    const rfidValues = Object.values(data);
    const uniqueRfids = new Set(rfidValues);

    // Check for duplicates locally
    if (uniqueRfids.size !== rfidValues.length) {
      setError("There is a duplicate RFID in this form");
      return;
    }


    setLoading(true);
    let userdata = {
        user: null,
        guest_adult: [],
        guest_child: []
    };

    if (rfidAdd?.user) {
      userdata.user = {
        id: rfidAdd.user.id,
        rfid: data[`rfid_${rfidAdd.user.id}`]
      }
    }
    if (rfidAdd?.guest_adult?.length > 0) {
        userdata.guest_adult = rfidAdd.guest_adult.map((item) => {
            return {
                id: item?.id,
                id_user: item?.id_user,
                rfid: data[`rfid_${item?.id_user}_${item?.id}`],
                status: item?.status,
                fullname: item?.fullname,
                user_name: item?.user_name,
                type_user: item?.type_user
            }
        })
    }
    if (rfidAdd?.guest_child?.length > 0) {
        userdata.guest_child = rfidAdd.guest_child.map((item) => {
            return {
                id: item?.id,
                id_user: item?.id_user,
                rfid: data[`rfid_${item?.id_user}_${item?.id}`],
                status: item?.status,
                fullname: item?.fullname,
                user_name: item?.user_name,
                type_user: item?.type_user
            }
        })
    }

    
    try {
    axios
        .post(CONFIG.URL + "/user/checkin", userdata)

        .then(function (response) {
        setLoading(false);
        if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess();
        } else {
        }
        })
        .catch(function (error) {
        setLoading(false);
        });
    } catch (error) {
    setLoading(false);
    }

  };

  const fieldRefs = useRef({});
  const submitButtonRef = useRef(null);

  const handleKeyUp = (e, id, nextId) => {
    if (e.target.value !== "" && nextId) {
      fieldRefs.current[nextId].focus();
    }
  };

  const triggerSubmit = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  useEffect(() => {
    // Initialize refs
    const ids = [];
    if (rfidAdd?.user) {
      ids.push(`rfid_${rfidAdd?.user.id}`);
    }
    if (rfidAdd?.guest_adult?.length > 0) {
      rfidAdd.guest_adult.forEach((item) =>
        ids.push(`rfid_${item?.id_user}_${item?.id}`)
      );
    }
    if (rfidAdd?.guest_child?.length > 0) {
      rfidAdd.guest_child.forEach((item) =>
        ids.push(`rfid_${item?.id_user}_${item?.id}`)
      );
    }

    setTotalField(ids.length);

    ids.forEach((id) => {
      fieldRefs.current[id] = null;
    });
  }, [rfidAdd]);

  let isFocused = false;

  const renderField = (id, placeholder, idFocus) => {
    let focus = false;
    if (!isFocused) {
      if (rfidAdd.user !== null && rfidAdd.user?.id === idFocus) {
        focus = true;
      } else if (
        rfidAdd.guest_adult?.length > 0 &&
        rfidAdd.guest_adult[0]?.id === idFocus
      ) {
        focus = true;
      } else if (
        rfidAdd.guest_child?.length > 0 &&
        rfidAdd.guest_child[0]?.id === idFocus
      ) {
        focus = true;
      }

      isFocused = true;
    }

    const resetRFID = (id) => {
        let input = document.getElementById(id);
        input.value = "";
        setValue(id, "")
    }

    const handleRFIDValidation = async (rfid) => {
      const payload = { rfid: rfid };
  
      var form_data = new FormData();
      for (var key in payload) {
        form_data.append(key, payload[key]);
      }
  
      try {
        const response = await axios.post(CONFIG.URL + "/user/valid-rfid", form_data);
        if (response?.data?.status === "sukses") {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        return false;
      }
    };

    

    return (
      <div className="flex space-x-1 w-full">
        <div>
          <input
            maxLength={10}
            id={id}
            autoFocus={focus}
            autoComplete="off"
            role="presentation"
            className="border-2 px-2 h-[38px] rounded w-full min-w-[200px] text-black font-normal"
            // {...register(id, { required: true })}
            {...register(id, {
              required: 'RFID Empty',
              minLength: {
                value: 10,
                message: 'RFID 10 Character'
              },
              maxLength: {
                value: 10,
                message: 'RFID 10 Character'
              },
              validate: handleRFIDValidation
            })}
            ref={(el) => (fieldRefs.current[id] = el)}
            onChange={(e) => {
              setValue(id, e.currentTarget.value);

              if(e.currentTarget.value.length === 10) {
                const nextIndex = Object.keys(fieldRefs.current).indexOf(id) + 1;
                const nextId = Object.keys(fieldRefs.current)[nextIndex];
  
                if (nextIndex !== totalField) {
                  handleKeyUp(e, id, nextId);
                } else {
                  triggerSubmit();
                }
              }
            }}
            placeholder={placeholder}
          />

{errors[`${id}`]?.type === "validate" ? (
                <span className="text-red-500 text-sm">RFID is already in use.</span>
              ) : (
                <span className="text-red-500 text-sm">
                 {errors[`${id}`]?.message}
                </span>
              )}
        
        </div>
        <button
          type="button"
          onClick={() => {
            resetRFID(id);
          }}
          className="border-b-2 h-[38px] bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
        >
          Clear
        </button>
      </div>
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {rfidAdd?.user && (
        <div className="w-full flex flex-col justify-center items-start">
          <div className="text-black mb-2">STAFF NAME</div>
          <div className="flex w-full space-x-2">
            <div className="w-full bg-gray-500 cursor-pointer rounded flex justify-start items-center px-4 h-[38px] text-center text-lg font-semibold text-white capitalize mb-1">
              <div>{rfidAdd.user?.fullname}</div>
            </div>
            {renderField(
              `rfid_${rfidAdd.user?.id}`,
              "RFID Staff",
              rfidAdd.user?.id
            )}
          </div>
        </div>
      )}

      {rfidAdd?.guest_adult?.length > 0 && (
        <div className="mt-3 flex justify-start items-center gap-2 flex-wrap">
          <div className="text-black">GUEST ADULT</div>
          {rfidAdd.guest_adult.map((item, key) => (
            <div
              key={item?.id}
              className="w-full flex flex-col justify-center items-start"
            >
              <div className="flex w-full space-x-2">
                <div className="w-full bg-gray-500 cursor-pointer rounded flex justify-start items-center px-4 h-[38px] text-center text-lg font-semibold text-white capitalize mb-1">
                  <div>{item?.fullname}</div>
                </div>
                {renderField(
                  `rfid_${item?.id_user}_${item?.id}`,
                  "RFID Guest Adult" + (key + 1),
                  item?.id
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {rfidAdd?.guest_child?.length > 0 && (
        <div className="mt-3 flex justify-start items-center gap-2 flex-wrap">
          <div className="text-black text-left">GUEST CHILD</div>
          {rfidAdd.guest_child.map((item, key) => (
            <div
              key={item?.id}
              className="w-full flex flex-col justify-center items-start"
            >
              <div className="flex w-full space-x-2">
                <div className="w-full bg-gray-500 cursor-pointer rounded flex justify-start items-center px-4 h-[38px] text-center text-lg font-semibold text-white capitalize mb-1">
                  <div>{item?.fullname}</div>
                </div>
                {renderField(
                  `rfid_${item?.id_user}_${item?.id}`,
                  "RFID Guest Child" + (key + 1),
                  item?.id
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      {
        error !== "" &&  <span className="text-red-500 text-sm my-4 flex justify-center items-center w-full text-center">{error}</span>
      }

      <div className="mx-auto flex justify-center items-center space-x-4 mt-5">
        <button
          type="button"
          onClick={() => setScreen("checkin")}
          className={`  px-2 py-2 rounded-lg text-white font-bold border-b-4 border-red-700 bg-red-500  w-[160px] text-center`}
        >
          GO BACK
        </button>

        <button
          ref={submitButtonRef}
          type="submit"
          className={` border-green-700 bg-green-500 px-5 py-2 rounded-lg text-white font-bold border-b-4   w-[160px] text-center`}
        >
          {loading ? "LOADING..." : "SUBMIT"}
        </button>
      </div>
    </form>
  );
};

export default FormRFID;
