import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import logo from "../../assets/logo-tha.png";
import InputEmail from "../../components/input/input-email";
import InputText from "../../components/input/input-text";
import InputNumber from "../../components/input/input-number";
import InputChoose from "../../components/input/input-choose";
import InputTextarea from "../../components/input/input-textarea";

const FormRegister = (props) => {
  const [loading, setLoading] = useState(false);
  const [attend, setAttend] = useState(null);
  const [guest, setGuest] = useState(null);

  const [msg, setMsg] = useState("");
  const {
    register,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (attend !== null) {
      setMsg("");
    }
  }, [attend]);

  useEffect(() => {
    if (guest !== null) {
      setMsg("");
    }
  }, [guest]);

  const onSubmit = (data) => {
    if (loading) return;

    setLoading(true);
    const payload = { ...data };

    try {
      axios
        .post(CONFIG.URL + "/user/added", payload, {
          headers: {
            token: props?.user?.token,
          },
        })
        .then(function (response) {
          setLoading(false);
          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess(data);
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

  const resetRFID = (id) => {
    let input = document.getElementById(id);
    input.value = "";
    setValue(id, "")
}

const handleRFIDValidation = async (rfid) => {
  const payload = { rfid: rfid };

  var form_data = new FormData();
  for (var key in payload) {
    form_data.append(key, payload[key]);
  }

  try {
    const response = await axios.post(CONFIG.URL + "/user/valid-rfid", form_data);
    if (response?.data?.status === "sukses") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col justify-center items-center  px-5 py-5 bg-white  rounded-md w-full md:w-1/2"
      >
        {/* <img
          src={logo}
          className="w-28 object-contain mb-10 bg-white p-3 rounded-2xl "
          alt=""
        /> */}

        <div className="text-center  mb-5">
          <div className="text-2xl font-bold mb-3 uppercase">Add User</div>
        </div>

        <div className="mb-10 w-full space-y-2">
          <InputText
            label={"Username:"}
            name={"username"}
            register={register}
            error={errors.username}
          />

          <div className="w-full flex flex-col">
            <label className="text-black w-full text-left">RFID</label>
            <div className="flex space-x-1 w-full">
        <div className="w-full">
          <input
            id={'rfid_input'}
            autoComplete="off"
            role="presentation"
            className="border-2 px-2 h-[38px] rounded w-full min-w-[200px] text-black font-normal"
            // {...register(id, { required: true })}
            {...register('rfid', {
              required: 'RFID Empty',
              minLength: {
                value: 10,
                message: 'RFID 10 Character'
              },
              validate: handleRFIDValidation
            })}
            
          />

{errors?.rfid?.type === "validate" ? (
                <span className="text-red-500 text-sm">RFID is already in use.</span>
              ) : (
                <span className="text-red-500 text-sm">
                 {errors?.rfid?.message}
                </span>
              )}
        
        </div>
        <button
          type="button"
          onClick={() => {
            resetRFID('rfid_input')
          }}
          className="border-b-2 h-[38px] bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
        >
          Clear
        </button>
      </div>
          </div>



          <InputText
            label={"Details:"}
            name={"details"}
            setValue={(val) => {
              setValue("details", val);
            }}
            register={register}
            error={errors.details}
            required={false}
          />
        </div>

        <div className="text-center font-bold text-red-500 mb-5">{msg}</div>

        <div className="mx-auto flex justify-center items-center space-x-4">
          <a
            href="/"
            className={`w-[160px] px-10 py-2 rounded-lg text-white font-bold border-b-4 border-red-700 bg-red-500  text-center`}
          >
            Cancel
          </a >
          <button
            type="submit"
            className={`w-[160px] px-10 py-2 rounded-lg text-white font-bold border-b-4 border-green-700 bg-green-500  text-center`}
          >
            {loading ? "Loading..." : "Confirm"}
          </button>
        </div>
      </form>
    </>
  );
};

export default FormRegister;
