import { useEffect, useRef, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";
import FormRFID from "./form-rfid";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();
  const [checkAllow, setCheckAllow] = useState("");
  const [listGuestAdult, setListGuestAdult] = useState([]);
  const [listGuestChild, setListGuestChild] = useState([]);

  const [staffAttend, setStaffAttend] = useState(false);

  const [screen, setScreen] = useState("checkin");
  const [rfidAdd, setRfidAdd] = useState({});

  useEffect(() => {
    initGuest();
  }, [props?.data?.user]);

  const toogleStaffAttend = () => {
    setStaffAttend(!staffAttend);
  };

  // useEffect(() => {
  //   if (checkAllow !== "") {
  //     if (checkAllow === "allowed-to-checkin") {
  //       setTimeout(() => {
  //         setLoading(true);

  //         handleCheckin();
  //       }, 10);
  //       return;
  //     } else if (checkAllow === "allowed-to-re-checkin") {
  //       setTimeout(() => {
  //         setLoading(true);

  //         handleReCheckin();
  //       }, 10);
  //       return;
  //     }
  //   }
  // }, [checkAllow]);

  const onSubmit = (data) => {
    debugger;
    if (loading) return;

    setLoading(true);

    if (checkAllow === "allowed-to-checkin") {
      handleCheckin();
    } else {
      handleReCheckin();
    }
  };

  const handleReCheckin = () => {
    const payload = {
      id: props?.user?.id,
    };

    try {
      axios
        .post(CONFIG.URL + "/user/reentry", payload)

        .then(function (response) {
          setLoading(false);
          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleSetForRFID = () => {
    let _user = { ...props?.data?.user };

    let _listGuestAdult = [...listGuestAdult];
    _listGuestAdult = _listGuestAdult.filter(
      (item) => item?.checkin_time === "0000-00-00 00:00:00"
    );
    _listGuestAdult = _listGuestAdult.filter((item) => item?.active);
    _listGuestAdult = _listGuestAdult.filter((item) => item?.rfid === "");

    let _listGuestChild = [...listGuestChild];
    _listGuestChild = _listGuestChild.filter(
      (item) => item?.checkin_time === "0000-00-00 00:00:00"
    );
    _listGuestChild = _listGuestChild.filter((item) => item?.active);
    _listGuestChild = _listGuestChild.filter((item) => item?.rfid === "");

    let obj = {
      user:
        staffAttend &&
        props?.data?.user?.checkin_time === "0000-00-00 00:00:00" &&
        props?.data?.user?.rfid === ""
          ? _user
          : null,
      guest_adult: _listGuestAdult,
      guest_child: _listGuestChild,
    };

    if (
      obj.user !== null ||
      obj.guest_adult.length !== 0 ||
      obj.guest_child.length !== 0
    ) {
      setRfidAdd(obj);
      setScreen("rfid-scan");
    } else {
      alert("Please Choose attende for checkin first");
    }
  };

  const handleCheckin = () => {
    const payload = {
      id: props?.user?.id,
    };

    try {
      axios
        .post(CONFIG.URL + "/user/checkin", payload)

        .then(function (response) {
          setLoading(false);
          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const handleAddAdult = () => {
    let obj = {
      id: Date.now() + "" + Math.floor(Math.random() * 1000000),
      id_user: props?.data?.user?.id,
      user_name: props?.data?.user?.fullname,
      fullname: "Adult Guest " + (listGuestAdult.length + 1),
      checkin_time: "0000-00-00 00:00:00",
      checkout_time: "0000-00-00 00:00:00",
      reentery_time: "0000-00-00 00:00:00",
      photo: "",
      registered_time: "0000-00-00 00:00:00",
      updated_time: "0000-00-00 00:00:00",
      type_user: props?.data?.user?.type_user,
      rfid: "",
      breakfast: "0000-00-00 00:00:00",
      lunch: "0000-00-00 00:00:00",
      snack_1: "0000-00-00 00:00:00",
      snack_2: "0000-00-00 00:00:00",
      snack_3: "0000-00-00 00:00:00",
      snack_4: "0000-00-00 00:00:00",
      category: "adult",
      active: false,
      canDelete: true,
      status: "new",
    };

    let arr = [...listGuestAdult];
    arr.push(obj);
    setListGuestAdult(arr);
  };

  const handleAddChild = () => {
    let obj = {
      id: Date.now() + "" + Math.floor(Math.random() * 1000000),
      id_user: props?.data?.user?.id,
      user_name: props?.data?.user?.fullname,
      fullname: "Child Guest " + (listGuestChild.length + 1),
      checkin_time: "0000-00-00 00:00:00",
      checkout_time: "0000-00-00 00:00:00",
      reentery_time: "0000-00-00 00:00:00",
      photo: "",
      registered_time: "0000-00-00 00:00:00",
      updated_time: "0000-00-00 00:00:00",
      type_user: props?.data?.user?.type_user,
      rfid: "",
      breakfast: "0000-00-00 00:00:00",
      lunch: "0000-00-00 00:00:00",
      snack_1: "0000-00-00 00:00:00",
      snack_2: "0000-00-00 00:00:00",
      snack_3: "0000-00-00 00:00:00",
      snack_4: "0000-00-00 00:00:00",
      category: "child",
      active: false,
      canDelete: true,
      status: "new",
    };

    let arr = [...listGuestChild];
    arr.push(obj);
    setListGuestChild(arr);
  };

  const handleDeleteAdult = (id) => {
    let arr = [...listGuestAdult];
    arr = arr.filter((item) => item?.id !== id);

    arr = arr.map((item, key) => {
      return {
        ...item,
        fullname: "Adult Guest " + (key + 1),
      };
    });

    setListGuestAdult(arr);
  };

  const handleDeleteChild = (id) => {
    let arr = [...listGuestChild];
    arr = arr.filter((item) => item?.id !== id);

    arr = arr.map((item, key) => {
      return {
        ...item,
        fullname: "Child Guest " + (key + 1),
      };
    });

    setListGuestChild(arr);
  };

  const toogleAdult = (id) => {
    let arr = [...listGuestAdult];
    let target = arr.find((item) => item?.id === id);

    target.active = target.active ? false : true;

    setListGuestAdult(arr);
  };

  const toogleChild = (id) => {
    let arr = [...listGuestChild];
    let target = arr.find((item) => item?.id === id);

    target.active = target.active ? false : true;

    setListGuestChild(arr);
  };

  const initGuest = () => {
    if (props?.data?.guest_adult?.length > 0) {
      let adult = props?.data?.guest_adult?.map((item) => {
        return {
          ...item,
          active: false,
          canDelete: false,
          status: "old",
        };
      });

      setListGuestAdult(adult);
    }

    if (props?.data?.guest_child?.length > 0) {
      let child = props?.data?.guest_child?.map((item) => {
        return {
          ...item,
          active: false,
          canDelete: false,
          status: "old",
        };
      });
      setListGuestChild(child);
    }
  };

  const initAllowed = () => {
    if (props?.data?.user !== null) {
      if (props?.data?.user?.checkin_time !== "0000-00-00 00:00:00") {
        if (props?.data?.user?.checkout_time === "0000-00-00 00:00:00") {
          setCheckAllow("already-checkin");
        } else {
          if (
            moment(props?.data?.user.reentery_time) >
            moment(props?.data?.user.checkout_time)
          ) {
            setCheckAllow("already-checkin");
          } else {
            setCheckAllow("allowed-to-re-checkin");
          }
        }
      } else {
        setCheckAllow("allowed-to-checkin");
      }
    }
  };

  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="">MAIN GATE</div>
          <div className="text-2xl uppercase">CHECK IN</div>
        </div>

        <div className="h-[2px] border w-full"></div>

        <div
          className={`pt-2 pb-2 cursor-pointer text-white flex flex-col justify-center items-center  text-black font-bold px-5 rounded`}
        >
          {props?.data?.user !== null ? (
            <>
              {screen === "checkin" && (
                <>
                  <div>
                    {
                      props?.data?.user?.checkin_time === "0000-00-00 00:00:00" ?
                      <div className="bg-green-500 w-full min-w-[200px] text-sm  text-center py-1 px-2 text-white font-bold rounded-t-lg">
                          ALLOWED TO ENTER
                        </div> :
                        <div className="bg-red-500 text-sm w-full min-w-[200px] text-center py-1 px-2 text-white font-bold rounded-t-lg">
                          <div className="text-xs">ALREADY CHECKIN</div>
                        <div>{props?.data?.user?.checkin_time}</div>
                      </div>
                    }
                    
                    <button
                      onClick={() => props?.data?.user?.checkin_time !== "0000-00-00 00:00:00" ? () => {} : toogleStaffAttend()}
                      className={`${
                        staffAttend || props?.data?.user?.checkin_time !== "0000-00-00 00:00:00" ? "bg-gray-500" : "bg-blue-700"
                      } cursor-pointer w-full min-w-[200px] flex flex-col justify-center items-center px-4 py-3 rounded-b-lg  text-center text-3xl font-semibold text-white capitalize mb-1`}
                    >
                      <div>{props?.data?.user?.fullname}</div>
                      <div className="text-lg mt-1">
                        {props?.data?.user?.department}
                      </div>
                    </button>
                  </div>

                  {listGuestAdult.length > 0 && (
                    <div className="mt-3 flex justify-center items-center gap-2 flex-wrap">
                      {listGuestAdult?.map((item, key) => {
                        return (
                          <div key={key} className="relative">
                            {item?.canDelete && (
                              <button
                                onClick={() => handleDeleteAdult(item?.id)}
                                className="z-10 text-xs bg-red-600 w-5 h-5 rounded-md absolute top-1 right-1 border border-white"
                              >
                                X
                              </button>
                            )}
                            {
                              item?.checkin_time === "0000-00-00 00:00:00" ?
                              <div className="bg-green-500 text-sm text-center w-[200px] py-1 px-2 text-white font-bold rounded-t-lg">
                                  ALLOWED TO ENTER
                                </div> :
                                <div className="bg-red-500 text-sm text-center w-[200px] py-1 px-2 text-white font-bold rounded-t-lg">
                                  <div className="text-xs">ALREADY CHECKIN</div>
                                <div>{item?.checkin_time}</div>
                              </div>
                            }
                            <button
                              key={key}
                              onClick={() => item.checkin_time === "0000-00-00 00:00:00" ?  toogleAdult(item?.id) : () => {}}
                              className={`${
                                item?.active || item.checkin_time !== "0000-00-00 00:00:00" ? "bg-gray-500" : "bg-cyan-600"
                              } relative cursor-pointer flex flex-col justify-center items-center w-[200px] px-4 py-3 rounded-b-lg text-center text-2xl font-semibold text-white capitalize mb-0`}
                            >
                              <div className="text-xl text-center">{item?.fullname}</div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {listGuestChild.length > 0 && (
                    <div className="mt-3 flex justify-center items-center gap-2 flex-wrap">
                      {listGuestChild?.map((item, key) => {
                        return (
                          <div key={key} className="relative">
                            {item?.canDelete && (
                              <button
                                onClick={() => handleDeleteChild(item?.id)}
                                className="z-10 text-xs bg-red-600 w-5 h-5 rounded-md absolute top-1 right-1 border border-white"
                              >
                                X
                              </button>
                            )}
                            {
                              item?.checkin_time === "0000-00-00 00:00:00" ?
                              <div className="bg-green-500 text-sm text-center w-[200px] py-1 px-2 text-white font-bold rounded-t-lg">
                                  ALLOWED TO ENTER
                                </div> :
                                <div className="bg-red-500 text-sm text-center w-[200px] py-1 px-2 text-white font-bold rounded-t-lg">
                                  <div className="text-xs">ALREADY CHECKIN</div>
                                <div>{item?.checkin_time}</div>
                              </div>
                            }
                            <button
                              key={key}
                              onClick={() => item.checkin_time === "0000-00-00 00:00:00" ?  toogleChild(item?.id) : () => {}}
                              className={`${
                                item?.active || item.checkin_time !== "0000-00-00 00:00:00"  ? "bg-gray-500" : "bg-purple-600"
                              } relative cursor-pointer flex flex-col justify-center items-center w-[200px] px-4 py-3 rounded-b-lg text-center text-2xl font-semibold text-white capitalize mb-0`}
                            >
                              <div className="text-xl text-center">{item?.fullname}</div>
                            </button>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  <div className="space-x-5 mt-5">
                    <button
                      onClick={() => handleAddAdult()}
                      className="bg-amber-800 text-white px-3 py-1 rounded-md font-semibold w-[140px] text-center"
                    >
                      Add Adult
                    </button>
                    <button
                      onClick={() => handleAddChild()}
                      className="bg-amber-800 text-white px-3 py-1 rounded-md font-semibold mt-2 w-[140px] text-center"
                    >
                      Add Child
                    </button>
                  </div>
                  <div className="mx-auto flex justify-center items-center space-x-4 mt-5">
                    <button
                      type="button"
                      onClick={() => props.ok()}
                      className={`  px-2 py-2 rounded-lg text-white font-bold border-b-4 border-red-700 bg-red-500  w-[160px] text-center`}
                    >
                      CANCEL
                    </button>

                    <button
                        type="button"
                        onClick={() => handleSetForRFID()}
                        className={` border-green-700 bg-green-500 px-5 py-2 rounded-lg text-white font-bold border-b-4   w-[160px] text-center`}
                      >
                        {loading ? "LOADING..." : "SUBMIT"}
                      </button>
                  </div>
                </>
              )}

              {screen === "rfid-scan" && (
                <FormRFID
                  rfidAdd={rfidAdd}
                  setScreen={(val) => setScreen(val)}
                  onSuccess={() => props.onSuccess()}
                />
              )}
            </>
          ) : (
            <>
              {" "}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FormRFIDResult;
