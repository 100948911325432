import { useEffect, useRef, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";
import FormRFID from "./form-rfid";

const FormRFIDResult = (props) => {
  const [loading, setLoading] = useState(false);
  const query = useQuery();

  const [breakfast, setBreakfast] = useState(false);
  const [lunch, setLunch] = useState(false);
  const [snack1, setSnack1] = useState(false);
  const [snack2, setSnack2] = useState(false);
  const [snack3, setSnack3] = useState(false);
  const [snack4, setSnack4] = useState(false);


  useEffect(() => {
    
  }, [props?.data?.user]);

  const toogleBreakfast = () => {
    setBreakfast(!breakfast);
  };

  const toogleLunch = () => {
    setLunch(!lunch);
  };

  const toogleSnack1 = () => {
    setSnack1(!snack1);
  };

  const toogleSnack2 = () => {
    setSnack2(!snack2);
  };

  const toogleSnack3 = () => {
    setSnack3(!snack3);
  };

  const toogleSnack4 = () => {
    setSnack4(!snack4);
  };


  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      id: props?.user?.id,
      category: props?.user?.category,
      lunch: lunch,
      breakfast: breakfast,
      snack1: snack1,
      snack2: snack2,
      snack3: snack3,
      snack4: snack4,
    };

    try {
      axios
        .post(CONFIG.URL + "/user/redemption", payload)

        .then(function (response) {
          setLoading(false);
          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess();
          } else {
          }
        })
        .catch(function (error) {
          setLoading(false);
        });
    } catch (error) {
      setLoading(false);
    }
  };

  const canCollected = () => {
    let ret = false;

    if(props?.user?.breakfast === "0000-00-00 00:00:00"){
      return true;
    }
    if(props?.user?.lunch === "0000-00-00 00:00:00"){
      return true;
    }
    if(props?.user?.snack_1 === "0000-00-00 00:00:00"){
      return true;
    }
    if(props?.user?.snack_2 === "0000-00-00 00:00:00"){
      return true;
    }
    if(props?.user?.snack_3 === "0000-00-00 00:00:00"){
      return true;
    }
    if(props?.user?.snack_4 === "0000-00-00 00:00:00"){
      return true;
    }

    return ret;
  }


  return (
    <>
      <div className="space-y-3 px-5 py-3 bg-white rounded-md items-center justify-center flex flex-col w-full md:w-1/2 transition-all">
        <div className="text-center font-bold">
          <div className="">MAIN GATE</div>
          <div className="text-2xl uppercase">REDEMPTION</div>
        </div>

{
  canCollected() ? <div className="uppercase bg-green-500 w-full text-center py-2 text-white font-bold rounded">
  ALLOWED TO COLLECT
</div> : <div className="uppercase bg-red-500 w-full text-center py-2 text-white font-bold rounded">
ALREADY COLLECTED
        </div>
}
        

        <div className="text-2xl font-bold py-2 flex flex-col justify-center items-center">
          <div> {props?.user?.fullname}</div>
          {
            props?.user?.user_name !== null && <div> ({props?.user?.user_name})</div>
          }
        </div>

        <div
          className={`pt-2 pb-2 cursor-pointer  flex flex-col justify-center items-center  text-black font-bold px-5 rounded`}
        >
          {props?.user !== null ? (
            <>

              <div className="grid grid-cols-2 gap-5">
                <button 
                  onClick={props?.user?.breakfast === "0000-00-00 00:00:00" ? () => toogleBreakfast() : () => {}}
                  className={`${
                    breakfast || props?.user?.breakfast !== "0000-00-00 00:00:00" ? "bg-gray-500" : "bg-blue-700"
                  } w-[200px] h-[80px]  rounded-lg flex flex-col justify-center items-center text-white`}>
                    <div>
                      BREAKFAST
                    </div>
                    {
                      props?.user?.breakfast !== "0000-00-00 00:00:00" && 
                      <div className="text-sm mt-2">
                        {props?.user?.breakfast }
                      </div>
                    }
                </button>

                <button 
                  onClick={props?.user?.lunch === "0000-00-00 00:00:00" ? () => toogleLunch() : () => {}}
                  className={`${
                    lunch || props?.user?.lunch !== "0000-00-00 00:00:00" ? "bg-gray-500" : "bg-blue-700"
                  } w-[200px] h-[80px]  rounded-lg flex flex-col justify-center items-center text-white`}>
                    <div>
                      {props?.user?.category === "child" ? "CHILD" : "ADULT"} LUNCH
                    </div>
                    {
                      props?.user?.lunch !== "0000-00-00 00:00:00" && 
                      <div className="text-sm mt-2">
                        {props?.user?.lunch }
                      </div>
                    }
                </button>

                <button 
                  onClick={props?.user?.snack_1 === "0000-00-00 00:00:00" ? () => toogleSnack1() : () => {}}
                  className={`${
                    snack1 || props?.user?.snack_1 !== "0000-00-00 00:00:00" ? "bg-gray-500" : "bg-blue-700"
                  } w-[200px] h-[80px]  rounded-lg flex flex-col justify-center items-center text-white`}>
                    <div>
                      SNACK 1
                    </div>
                    {
                      props?.user?.snack_1 !== "0000-00-00 00:00:00" && 
                      <div className="text-sm mt-2">
                        {props?.user?.snack_1 }
                      </div>
                    }
                </button>

                <button 
                  onClick={props?.user?.snack_2 === "0000-00-00 00:00:00" ? () => toogleSnack2() : () => {}}
                  className={`${
                    snack2 || props?.user?.snack_2 !== "0000-00-00 00:00:00" ? "bg-gray-500" : "bg-blue-700"
                  } w-[200px] h-[80px]  rounded-lg flex flex-col justify-center items-center text-white`}>
                    <div>
                      SNACK 2
                    </div>
                    {
                      props?.user?.snack_2 !== "0000-00-00 00:00:00" && 
                      <div className="text-sm mt-2">
                        {props?.user?.snack_2 }
                      </div>
                    }
                </button>

                {/* <button 
                  onClick={props?.user?.snack_3 === "0000-00-00 00:00:00" ? () => toogleSnack3() : () => {}}
                  className={`${
                    snack3 || props?.user?.snack_3 !== "0000-00-00 00:00:00" ? "bg-gray-500" : "bg-blue-700"
                  } w-[200px] h-[80px]  rounded-lg flex flex-col justify-center items-center text-white`}>
                    <div>
                      SNACK 3
                    </div>
                    {
                      props?.user?.snack_3 !== "0000-00-00 00:00:00" && 
                      <div className="text-sm mt-2">
                        {props?.user?.snack_3 }
                      </div>
                    }
                </button>

                <button 
                  onClick={props?.user?.snack_4 === "0000-00-00 00:00:00" ? () => toogleSnack4() : () => {}}
                  className={`${
                    snack4 || props?.user?.snack_4 !== "0000-00-00 00:00:00" ? "bg-gray-500" : "bg-blue-700"
                  } w-[200px] h-[80px]  rounded-lg flex flex-col justify-center items-center text-white`}>
                    <div>
                      SNACK 4
                    </div>
                    {
                      props?.user?.snack_4 !== "0000-00-00 00:00:00" && 
                      <div className="text-sm mt-2">
                        {props?.user?.snack_4 }
                      </div>
                    }
                </button> */}
              </div>

              <div className="mx-auto flex justify-center items-center space-x-4 mt-10">
                <button
                  type="button"
                  onClick={() => props.ok()}
                  className={`  px-2 py-2 rounded-lg text-white font-bold border-b-4 border-red-700 bg-red-500  w-[160px] text-center`}
                >
                  CANCEL
                </button>

{
  canCollected() &&    <button
  type="button"
  onClick={() => handleSubmit()}
  className={` border-green-700 bg-green-500 px-5 py-2 rounded-lg text-white font-bold border-b-4   w-[160px] text-center`}
>
  {loading ? "LOADING..." : "SUBMIT"}
</button>
}
             
              </div>
            </>
          ) : (
            <>
              {" "}
              <div className="text-center  font-bold text-black text-4xl mt-4">
                NOT FOUND
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default FormRFIDResult;
