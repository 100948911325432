import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import logo from "../../assets/logo-tha.png";

const FormVerification = (props) => {
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const [msg, setMsg] = useState("");
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {};

  return (
    <>
      <div className="flex flex-col justify-center items-center  px-5 py-5 bg-white rounded-md w-full md:w-1/2">
        <img
          src={logo}
          className="w-28 object-contain mb-10 bg-white p-3 rounded-2xl "
          alt=""
        />

        <div className="text-center  mb-5">
          <div className="text-2xl font-bold mb-3">Welcome!</div>
          <div className="text-lg">
            Please Click Below to Register for the THA Vendor Sensitization
            Session
          </div>
          <div className="text-sm mt-5">Tuesday 7th May 2024 at 10am - 12noon.</div>
          <div className="text-sm">
            Guests are asked to be seated by 9:30am.
          </div>
          <div className="text-sm">
            Main Auditorium, Shaw Park Complex, Shaw Park, Tobago.
          </div>
        </div>

        <div className="flex justify-center items-center mt-10">
          <button
            type="submit"
            onClick={() => props.onSuccess()}
            className={`mx-auto w-max px-10 py-2 rounded-lg text-white font-bold border-b-4 border-blue-700 bg-blue-500 text-center`}
          >
            Register
          </button>
        </div>
      </div>
    </>
  );
};

export default FormVerification;
