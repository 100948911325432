import { useEffect, useState } from "react";
import QRScan from "../../components/qr_scan";
import { Controller, useForm } from "react-hook-form";
import CONFIG from "../../constanta/config";
import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import useUser from "../../hook/useUser";
import ReactSelect from "react-select";
import useReport from "../../hook/useReport";
import moment from "moment";
import { useQuery } from "../../hook/useQuery";
import helper from "../../utils/helper";

const FormVerification = (props) => {
  const localReport = useReport();
  const [loading, setLoading] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [focus, setFocus] = useState("rfid");
  const [msg, setMsg] = useState("");
  const [count, setCount] = useState(0);
  const [showQR, setShowQR] = useState(props.qrCode);

  useEffect(() => {
    setShowQR(props.qrCode);
  }, [props.qrCode]);

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { errors },
  } = useForm();

  const query = useQuery();

  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
      handleCheckinTime();
    }, 1000 * 5);

    return () => clearInterval(interval);
  }, [count]);

  useEffect(() => {
    localReport.fetchData();
  }, []);

  const handleCheckinTime = async () => {
    localReport.fetchData();
  };

  useEffect(() => {
    setTimeout(() => {
      setMsg(null);
    }, 1500);
  }, [msg]);

  const onSubmit = (data) => {
    checkRFID(data);
    return;
  };

  

  const checkRFID = async (data) => {
    if (loading) return;

    setLoading(true);
    setMsg("");

    const payload = {
      rfid: data.rfid,
    };

    try {
      axios
        .post(CONFIG.URL + "/user/check-rfid", payload)
        .then(function (response) {
          setLoading(false);

     
          if (response.status === 200 && response.data?.status !== "error") {
            props.onSuccess(response?.data?.data, "rfid", showQR);
          } else {
            setMsg("Error: " + response.data?.message);
          }
        })
        .catch(function (error) {
          setMsg("Error: Something went wrong please try again");
          setLoading(false);
        });
    } catch (error) {
      setMsg("Error: Something went wrong please try again");
      setLoading(false);
    }
  };

 
  const resetForm = () => {
    setValue("rfid", "");
  };

  const handleCloseScan = () => {
    setShowQR(false);
  };

  const handleSuccess = (qr) => {
    handleCloseScan();
    checkRFID({
      rfid: qr,
    });
    //checkDataUser(qr);
  };

  const handleFailed = () => {
    setShowQR(false);
  };

  const calcTotalCurrCheckin = () => {
    let total = 
    (parseInt(localReport?.data?.total_checkin ?? 0)) -
    (parseInt(localReport?.data?.total_checkout ?? 0)) +
    (parseInt(localReport?.data?.total_reentry ?? 0)) ;

    return total;
  }

  return (
    <>
      {showQR && (
        <QRScan
          onClose={() => handleCloseScan()}
          onSuccess={(data) => handleSuccess(data)}
          onFailed={() => handleFailed()}
          gate="CHECK IN"
          event={""}
        />
      )}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="relative space-y-5 px-5 py-5 bg-white rounded-md w-full md:w-3/4 z-0"
      >


        <div className="text-center font-bold mb-10">
          {/* <div className="text-2xl">{focus}</div> */}
          <div className="">MAIN GATE</div>
          <div className="text-2xl uppercase">CHECKOUT</div>
          <div className="text-black font-semibold text-xl">
            Currently Checked In: {calcTotalCurrCheckin()}
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-1 gap-2 md:gap-6">
          {/* SEARCH NAME */}
          {/* <div className="relative z-0 flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">Search Name</label>
            </div>

            <Controller
              control={control}
              name="rfid"
              rules={{ required: false }}
              render={({ field: { onChange, value, ref, name } }) => (
                <ReactSelect
                  components={{
                    DropdownIndicator: () => null,
                    IndicatorSeparator: () => null,
                  }}
                  isClearable
                  dropdown
                  onFocus={() => setFocus("rfid")}
                  className={"react-select"}
                  classNamePrefix={"react-select"}
                  placeholder={"Search Name"}
                  options={props.options}
                  onInputChange={(val) => {
                    if (val.length <= 2) {
                      setOpenMenu(false);
                    } else {
                      setOpenMenu(true);
                    }
                  }}
                  onChange={(val) => {
                    onChange(val?.value);
                  }}
                  menuIsOpen={openMenu}
                  openMenuOnClick={false}
                  openMenuOnFocus={false}
                />
              )}
            />
            <span className="text-xs text-gray-500 mt-1">
              *type at least three characters
            </span>

            <span className="text-xs text-gray-500 mt-1">
              {props.loadingFetchUser
                ? "please wait prepare for prediction..."
                : ""}
            </span>
          </div> */}


          {/* SCAN QR CODE / RFID */}
          <div className="flex flex-col">
            <div className="flex justify-between mb-1">
              <label className="text-black w-full font-bold">
               Scan RFID
              </label>
              <button
                type="button"
                onClick={() => resetForm()}
                className="border-b-2 border-red-700  bg-red-500 px-2 py-1 text-white text-xs rounded font-bold"
              >
                Clear
              </button>
            </div>

            <input
            autoFocus
              autoComplete="off"
              role="presentation"
              onFocus={() => setFocus("rfid")}
              className="border-2 px-2 h-[38px] rounded"
              {...register("rfid", { required: false })}
            />
          </div>
        </div>

        <div className="text-center font-bold text-red-500">{msg}</div>

        

        {focus === "rfid" && (
          <div className="grid md:grid-cols-1 gap-3">
            {/* <button
              type="button"
              onClick={() => setShowQR(true)}
              className={`border-b-4 border-green-700 md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-green-500  w-full text-center order-1`}
            >
              Mobile Scan
            </button> */}
            <button
              type="submit"
              className={`border-b-4 border-blue-700 md:min-w-[160px] whitespace-nowrap px-2 py-2 rounded-lg text-white font-bold bg-blue-500  w-full text-center`}
            >
              {loading ? "Loading..." : "Submit"}
            </button>
          </div>
        )}

       
      </form>
    </>
  );
};

export default FormVerification;
